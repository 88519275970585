import Swiper from 'swiper';
import Swiwaypointper from 'waypoints/lib/noframework.waypoints.min.js';
// import remodal from 'remodal';
import Marquee3k from 'marquee3000';

// スクロール
var Ease = {
  easeInOut: t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
};
var duration = 500;
window.addEventListener('DOMContentLoaded', () => {
  var smoothScrollTriggers = document.querySelectorAll('.anchor');
  smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
    smoothScrollTrigger.addEventListener('click', function (e) {
      var href = smoothScrollTrigger.getAttribute('href');
      var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
      var targetElement = document.getElementById(href.replace('#', ''));
      if (targetElement) {
        e.preventDefault();
        e.stopPropagation();
        var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - 0;
        var startTime = performance.now();
        var loop = function (nowTime) {
          var time = nowTime - startTime;
          var normalizedTime = time / duration;
          if (normalizedTime < 1) {
            window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)));
            requestAnimationFrame(loop);
          } else {
            window.scrollTo(0, targetPosition);
          }
        };
        requestAnimationFrame(loop);
      }
    });
  });
});

// ニュースティッカー
Marquee3k.init();

window.addEventListener('load', function () {
  //オープニング
  // const spinner = document.getElementById('loading');
  // // Add .loaded to .loading
  // spinner.classList.add('loaded');

  // スライダー
  let slide = document.querySelectorAll('.slide');
  for (let i = 0; i < slide.length; i++) {
    const swiper = document.querySelectorAll('.swiper-container')[i];
    const swiperSlide = swiper.querySelectorAll('.swiper-slide').length;
    if (swiperSlide <= 1) {
      new Swiper(swiper, {
        watchOverflow: true,
        pagination: {
          el: document.querySelectorAll('.swiper-pagination')[i],
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: document.querySelectorAll('.swiper-button-next')[i],
          prevEl: document.querySelectorAll('.swiper-button-prev')[i],
        },
      });
    } else {
      new Swiper(swiper, {
        loop: true,
        // hashNavigation: {
        //   replaceState: true,
        // },
        pagination: {
          el: document.querySelectorAll('.swiper-pagination')[i],
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: document.querySelectorAll('.swiper-button-next')[i],
          prevEl: document.querySelectorAll('.swiper-button-prev')[i],
        },
      });
    }
  }


  //アニメーション
  const sections = Array.prototype.slice.call(
    document.getElementsByClassName('anim')
  );
  const waypoints = sections.map(section => {
    return new Waypoint({
      element: section,
      handler: direction => {
        if (direction === 'down') { //scroll down
          section.classList.add('active');
        }
        // else { //scroll up
        //   section.classList.remove('active');
        // }
      },
      offset: '85%'
    });
  });

  const sectionsTop = Array.prototype.slice.call(
    document.getElementsByClassName('animTop')
  );
  const waypointsTop = sectionsTop.map(section => {
    return new Waypoint({
      element: section,
      handler: direction => {
        if (direction === 'down') { //scroll down
          section.classList.add('active');
        }
        // else { //scroll up
        //   section.classList.remove('active');
        // }
      },
      offset: '100%'
    });
  });
});
